import {useState} from 'react';
import {Container, Row, Col, Button, Form, Card, InputGroup} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useEffect } from 'react';
import {baseURL} from '../../constants';
import { toast } from 'react-toastify';
import Loader from '../Loader';
import Message from '../Message';



const LogInScreen = () => {
    const [username, setUsername] = useState('');
    const [pass1, setPass1] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('activation')) {
            toast.success("Activation link has been sent to your email!", {
                className: 'bg-dark text-white',
            });
        }
    }, []);

    // Toggle visibility of password
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const submitHandler = (e) => {
        e.preventDefault();
    
        const payload = {username, password: pass1}; // Ensure correct structure
        setIsPending(true);
    
        fetch(`${baseURL}/api/users/login/`, {  // Use the correct login URL
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(payload)
        })
        .then(async (response) => {
            setIsPending(false);
            
            if (!response.ok) {
                const data = await response.json();
                if (data.errors) {
                    setError(data.errors); // Set the backend errors
                } else {
                    setError({ general: "Invalid username or password" });
                }
                return;
            }

            // Get the token from response and save it (for example, in localStorage)
            const data = await response.json();
            // console.log(data);
            localStorage.setItem('authToken', data.access); // Store token in localStorage
            localStorage.setItem('authRefreshToken', data.refresh); // Store token in localStorage
            localStorage.setItem('isLoggedIn', true);
            window.dispatchEvent(new Event("storage"));
            
            // Redirect to homepage after successful login
            navigate('/');
        })
        .catch((err) => {
            setIsPending(false);
            setError({ general: err.message });
        });
    };

    return ( 
        <Container className="login mt-3">
            {isPending && <Loader />}
            <Row>
                <Col md={4}></Col>
                <Col md={4}>
                    <Card>
                        <Card.Header as="h3" className="text-center">
                            LogIn
                        </Card.Header>
                        <Card.Body>

                            {error?.general && <Message variant='danger'>{error.general}</Message>}

                            <Form onSubmit={submitHandler}>
                                <Form.Group className='mb-3' controlId="username">
                                    <Form.Label>User Name: </Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        required 
                                        placeholder='Enter Username' 
                                        value={username} 
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    {error?.username && error.username.map((err, index) => (
                                        <Message key={index} variant='danger'>{err}</Message>
                                    ))}
                                </Form.Group>
                                
                                <Form.Group className='mb-3' controlId="password">
                                    <Form.Label>Password: </Form.Label>
                                    <InputGroup>
                                        <Form.Control 
                                            type={showPassword ? 'text' : 'password'} 
                                            placeholder='Enter Password' 
                                            required 
                                            value={pass1}
                                            onChange={(e) => setPass1(e.target.value)}
                                        />
                                        <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {error?.password && error.password.map((err, index) => (
                                        <Message key={index} variant='danger'>{err}</Message>
                                    ))}
                                </Form.Group>

                                <br/>
                                <div className="d-grid gap-2">
                                    <Button className="btn btn-md btn-success" type='submit'>LogIn</Button>
                                </div>
                            </Form>

                            <Row className='py-3'>
                                <Col>
                                    Not An User?
                                    <Link to="/signup"> SignUp</Link>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}></Col>
            </Row>
        </Container>
     );
}
 
export default LogInScreen;