import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import {logout, isAuthenticated} from '../services/authService'


const Header = () => {

    const [authenticated, setAuthenticated] = useState(isAuthenticated());
    const navigate = useNavigate();

    useEffect(() => {
        const listenStorageChange = () => {
            setAuthenticated(JSON.parse(localStorage.getItem("isLoggedIn")));
        };

        window.addEventListener("storage", listenStorageChange);
        return () => window.removeEventListener("storage", listenStorageChange);
    }, []);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return ( 
        <Navbar className="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
        <div className="container-fluid">
            <LinkContainer to="/">
                <Nav.Link className="navbar-brand">ShoppIt</Nav.Link>
            </LinkContainer>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarColor02">
            <ul className="navbar-nav me-auto">
                <li className="nav-item">
                    <LinkContainer to="/">
                        <Nav.Link className="nav-link active">Home </Nav.Link>
                    </LinkContainer>
                </li>
                <li className="nav-item">
                    <LinkContainer to="/cart">
                        <Nav.Link className="nav-link">Cart </Nav.Link>
                    </LinkContainer>
                </li>

                <li className="nav-item dropdown">
                
                <Nav.Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">User</Nav.Link>
                
                    {authenticated ? (
                        // Show only LogOut if authenticated
                        <div className="dropdown-menu" id="dp-menu">
                            <Nav.Link className="dropdown-item" onClick={handleLogout}>LogOut</Nav.Link>
                        </div>

                    ) : (
                        // Show LogIn and SignUp if not authenticated
                        <div className="dropdown-menu" id="dp-menu">
                            <LinkContainer to="/login">
                                <Nav.Link className="dropdown-item">LogIn</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/signup">
                                <Nav.Link className="dropdown-item">SignUp</Nav.Link>
                            </LinkContainer>
                        </div>
                    )}
                
                </li>
            </ul>
            <form className="d-flex">
                <input className="form-control me-sm-2" type="search" placeholder="Search" />
                <button className="btn btn-secondary my-2 my-sm-0" type="submit">Search</button>
            </form>
            </div>
        </div>
        </Navbar>
     );
}
 
export default Header;