import {useState} from 'react';
import {Container, Row, Col, Button, Form, Card, InputGroup} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {baseURL} from '../../constants'
import Loader from '../Loader';
import Message from '../Message';



const SignUpScreen = () => {

    const [first_name, setFname] = useState('');
    const [last_name, setLname] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [pass1, setPass1] = useState('');
    const [pass2, setPass2] = useState('');

    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const navigate = useNavigate();

    // Toggle visibility of password
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Toggle visibility of confirm password
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const submitHandler = (e) => {
        e.preventDefault();
    
        const payload = {username, first_name, last_name, email, password: pass1, password2: pass2};
        setIsPending(true);
    
        fetch(`${baseURL}/api/users/register/`, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(payload)
        })
        .then(async (response) => {
            setIsPending(false);
            
            if (!response.ok) {
                // Parse the error response
                const data = await response.json();
                if (data.errors) {
                    setError(data.errors); // Set the errors from the backend
                } else {
                    setError({ general: "Something went wrong. Please try again." });
                }
                return;
            }
            
            console.log('New user added!');
            navigate('/login?activation=true');
        })
        .catch((err) => {
            setIsPending(false);
            setError({ general: err.message });
        });
    };


    return ( 
        <Container className="signup mt-3">
            {isPending && <Loader />}
            <Row>
                <Col md={4}></Col>
                <Col md={4}>
                    <Card>
                        <Card.Header as="h3" className="text-center">
                            SignUp
                        </Card.Header>
                        <Card.Body>

                            {error?.general && <Message variant='danger'>{error.general}</Message>}

                            <Form onSubmit={submitHandler}>
                                <Form.Group className='mb-3' controlId="username">
                                    <Form.Label>User Name: </Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        required 
                                        placeholder='Enter Username' 
                                        value={username} 
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    {error?.username && error.username.map((err, index) => (
                                        <Message key={index} variant='danger'>{err}</Message>
                                    ))}
                                </Form.Group>

                                <Form.Group className='mb-3' controlId="first_name">
                                    <Form.Label>First Name: </Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        required placeholder='First Name' 
                                        value={first_name} 
                                        onChange={(e) => setFname(e.target.value)}
                                    />
                                </Form.Group>
                                
                                <Form.Group className='mb-3' controlId="last_name">
                                    <Form.Label>Last Name: </Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        required 
                                        placeholder='Last Name' 
                                        value={last_name} 
                                        onChange={(e) => setLname(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className='mb-3' controlId="email">
                                    <Form.Label>Email: </Form.Label>
                                    <Form.Control 
                                        type='email' 
                                        placeholder='name@company.com' 
                                        required 
                                        value={email} 
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {error?.email && error.email.map((err, index) => (
                                        <Message key={index} variant='danger'>{err}</Message>
                                    ))}
                                </Form.Group>
                                
                                <Form.Group className='mb-3' controlId="password">
                                    <Form.Label>Password: </Form.Label>
                                    <InputGroup>
                                        <Form.Control 
                                            type={showPassword ? 'text' : 'password'} 
                                            placeholder='Enter Password' 
                                            required 
                                            value={pass1}
                                            onChange={(e) => setPass1(e.target.value)}
                                        />
                                        <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <small>Password must include at least [1-9][A-z][_$@*!..] & 5 chars</small>
                                    {error?.password && error.password.map((err, index) => (
                                        <Message key={index} variant='danger'>{err}</Message>
                                    ))}
                                </Form.Group>

                                <Form.Group className='mb-3' controlId="password2">
                                    <Form.Label>Confirm Password: </Form.Label>
                                    <InputGroup>
                                        <Form.Control 
                                            type={showConfirmPassword ? 'text' : 'password'} 
                                            placeholder='Confirm Password' 
                                            required 
                                            value={pass2}
                                            onChange={(e) => setPass2(e.target.value)}
                                        />
                                        <InputGroup.Text onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer' }}>
                                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {error?.password2 && error.password2.map((err, index) => (
                                        <Message key={index} variant='danger'>{err}</Message>
                                    ))}
                                </Form.Group>

                                <br/>
                                <div className="d-grid gap-2">
                                    <Button className="btn btn-md btn-success" type='submit'>SignUp</Button>
                                </div>
                            </Form>

                            <Row className='py-3'>
                                <Col>
                                    Already User?
                                    <Link to="/login"> LogIn</Link>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}></Col>
            </Row>
        </Container>
     );
}
 
export default SignUpScreen;