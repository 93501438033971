import useFetch from "../../useFetch"
import Rating from "../Rating"
import { useParams } from "react-router-dom"
import { baseURL } from "../../constants";
import { Row, Col, Container, Image, ListGroup, Card, Button } from "react-bootstrap";


const ProductScreen = () => {
    const {id} = useParams();

    function parseImg(imPath) {
        return `${baseURL}${imPath}`;
    };

    const {data: product, error, isPending} = useFetch(`${baseURL}/api/product/${id}`);

    return (
        <div className="product-detail">
            {isPending && <div>Loading...</div>}
            {error && <div>{error}</div>}
            {product && (
                <Container>
                    <br />
                    <Row>
                        <Col md={7}>
                            <Image src={parseImg(product.image)} alt={product.product_name} fluid/>
                        </Col>
                        <Col md={3}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <h4>{product.product_name}</h4>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Rating
                                    value={product.rating}
                                    text={`${product.num_reviews} reviews`}
                                    color={"#f8e825"}
                                />
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    Category: {product.product_category}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    Brand: {product.product_brand}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    Description: {product.product_info}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    Seller: {product.seller}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={2}>
                            <Card>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Row>
                                    <Col>Price:</Col>
                                    <Col>
                                        <strong>{product.price} Rs</strong>
                                    </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                    <Col>Status:</Col>
                                    <Col>
                                        {product.stock_count > 0 ? "In Stock" : "Out of Stock"}
                                    </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Button className='btn-block btn-success' disabled={product.stock_count===0} type='button'>Add to Cart</Button>
                                </ListGroup.Item>
                            </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
     );
}
 
export default ProductScreen