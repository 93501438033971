import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import HomeScreen from "./components/screens/HomeScreen";
import SignUpScreen from "./components/screens/SignUpScreen";
import LogInScreen from "./components/screens/LogInScreen";
import CartScreen from "./components/screens/CartScreen";
import ProductScreen from "./components/screens/ProductScreen";

import { BrowserRouter as Router, Routes, Route } from 
"react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <ToastContainer />
        <Routes>
          <Route exact path="/" element={<HomeScreen />}></Route>
          <Route exact path="/login" element={<LogInScreen />}></Route>
          <Route exact path="/signup" element={<SignUpScreen />}></Route>
          <Route exact path="/cart" element={<CartScreen />}></Route>
          <Route exact path="/product/:id" element={<ProductScreen />}></Route>
          <Route exact path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
