import { Container, Row, Col } from "react-bootstrap";
import useFetch from "../../useFetch"
import Product from "../Product";

import { baseURL } from '../../constants'


const HomeScreen = () => {

    const {data: products, error, isPending} = useFetch(`${baseURL}/api/products`);

    return ( 
        <Container>
            <br />
            <div className="home">
                {isPending && <div>Loading...</div>}
                {error && <div>{error}</div>}
                {products && (
                    <div>
                        <h1>Products</h1>
                        <Row>
                            {products.map((product) => (
                                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                                    <Product product={product}/>
                                </Col>
                            ))}
                        </Row>
                    </div>
                )}
            </div>
        </Container>
     );
}
 
export default HomeScreen;