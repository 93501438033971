import { Card } from "react-bootstrap";
import Rating from "./Rating"
import {Link} from "react-router-dom"

import { baseURL } from "../constants";


const Product = ({product}) => {

    function parseImg(imPath) {
        return `${baseURL}${imPath}`;
    };

    return ( 
        <Card className="products my-3 p-3 rounded">
            <Link to={`/product/${product._id}`}>
                <Card.Img src={parseImg(product.image)} style={{ width: "100%", height: "260px", objectFit: "cover" }} />
            </Link>
            <Card.Body>
                <Link className="text-light" style={{'textDecoration': 'none'}}>
                    <Card.Title as="h3">
                        <strong>{product.product_name}</strong>
                    </Card.Title>
                </Link>

                {/* <Card.Text as="div">
                    <div className="my-3">
                        {product.rating} from {product.num_reviews} reviews
                    </div>
                </Card.Text> */}
                <Card.Text as="h5">
                    Rs. {product.price} /-
                </Card.Text>
                <Rating
                    value={product.rating}
                    text={`${product.num_reviews} reviews`}
                    color={"#f8e825"}
                />
            </Card.Body>
        </Card>
     );
}
 
export default Product;